<!--
 * @Author: youdedong
 * @Date: 2023-09-01 15:49:40
 * @Description:
-->
<template>
  <div class="wrap-content">
    <Header @showNav="handleNav" />
    <div class="view">
      <unComplete v-if="tab == 0" :disabled="isDisabled" />
      <Complete v-if="tab == 1" :disabled="isDisabled" />
    </div>
    <Footer />
  </div>
</template>

<script setup>
import { ref, inject, provide, onMounted } from "vue";
import unComplete from "./uncompleteData.vue";
import Complete from "./completeData.vue";
import Header from "./components/Header.vue";
import Footer from "@/components/footer.vue";
import bus from "@/utils/bus.js";
import { useRouter } from "vue-router";
const router = useRouter();

let tab = ref(0);
function handleNav(val) {
  tab.value = val;
}
let axios = inject("axios");
let isDisabled = ref(false);

//验证数据目录跳转路径
const validatePath = () => {
  let url = `/message/content/check?year=${currentYear.value}`;
  axios.get(url).then((res) => {
    if (res.data.code == 200) {
      let data = res.data.data;
      if (data.isDeclare ||data.isCompleted ) {
        isDisabled.value = true;
        bus.emit("disabledData", true);
      }
    }
  });
};
let currentYear=ref("");
//获取系统年度时间
const getStatusInfo = () => {
  let url = `/setting/getFillState`;
  axios.get(url).then((res) => {
    if (res.data.code == 200) {
      let data = res.data.data;
      currentYear.value=data.year;
      validatePath();
    }
  });
};
onMounted(() => {
  getStatusInfo();
  bus.on("noticeValidate", () => {
    getStatusInfo();
  });
});
</script>

<style lang="scss" scoped>
.wrap-content {
  padding: 0 40px;
  background: #fff;
  min-height: 100vh;
}
.view {
  min-height: calc(100vh - 135px);
}
// .empty {
//   height: calc(100vh - 110px);
// }
</style>
