<template>
  <div class="search">
    <ul>
      <li>
        <!-- <span>名称</span> -->
        <Input placeholder="请输入名称" v-model="name" class="width-300" @on-enter="handleSearch" />
      </li>
      <li>
        <el-cascader :props="props" :options="dataTypeList" v-model="dataType" filterable class="width-300"
          placeholder="请选择数据分类" />
      </li>
      <li>
        <!-- <span>数据级别</span> -->
        <Select placeholder="请选择数据级别" class="width-300" v-model="level">
          <Option label="重要数据" value="重要数据"></Option>
          <Option label="核心数据" value="核心数据"></Option>
          <!-- <Option label="一般" value="一般"></Option> -->
        </Select>
      </li>
      <li>
        <div class="btn search-btn flex-center-center" @click="handleSearch">
          <div class="img"></div>
        </div>
        <div class="btn reset-btn flex-center-center" @click="handleReset"
          v-if="name != '' || dataType.length > 0 || level != ''">
          <div class="img"></div>
        </div>
      </li>
      <li class="static">
        <span>共 <span class="bold">{{ total }} </span>项</span>
        <span v-if="selection.length > 0">已选
          <span class="bold">&nbsp; {{ selection.length }}&nbsp; </span>项</span>
      </li>
    </ul>
    <div>
      <div class="btn margin-right-10 btn-simple" @click="exportList">
        导出清单
      </div>
      <div class="btn btn-simple margin-right-10" @click="handleSingle(true)">
        删除
      </div>
      <div class="btn btn-simple" @click="confirmSubmit(true)">提交</div>
    </div>
  </div>
  <div class="table" v-if="total > 0">
    <Table :columns="columns" :data="tableData" border @on-selection-change="changeAll">
      <template #dataName="{ row }">
        <Tooltip :content="row.dataName" placement="top" max-width="300" theme="light" :transfer="true">
          <span class="name" @click="handleEdit(row)">{{ row.dataName }}</span>
        </Tooltip>
        <!-- <span class="name">{{ row.dataName }}</span> -->
      </template>
      <template #status="{ row }">
        <span v-if="row.state == 0" class="draft">草稿</span>
        <span v-if="row.state == 1" class="examine">复核中</span>
        <span v-if="row.state == 2" class="nothrough">未通过</span>
      </template>
      <template #dataSize="{ row }">
        <span>{{ row.dataSize }}GB</span>
      </template>
      <template #level="{ row }">
        <span>{{ row.level }}</span>
        <div class="rate" v-if="row.level == '核心数据'">
          <img src="../../assets/img/级别.png" alt="" />
          <img src="../../assets/img/级别.png" alt="" />
          <img src="../../assets/img/级别.png" alt="" />
        </div>
        <div class="rate" v-if="row.level == '重要数据'">
          <img src="../../assets/img/级别.png" alt="" />
          <img src="../../assets/img/级别.png" alt="" />
        </div>
        <div class="rate" v-if="row.level == '一般数据'">
          <img src="../../assets/img/级别.png" alt="" />
        </div>
      </template>
      <template #type="{ row }">
        <template v-if="row.level == '重要数据' || row.level == '核心数据'">
          <Tooltip placement="top" max-width="300" theme="light">
            <template #content>
              <span v-if="row.first != ''"> {{ row.first }}></span>
              <span v-if="row.second != ''">{{ row.second }}></span>
              <span v-if="row.second == ''">{{ row.second }}</span>
              <span v-if="row.third != ''">{{ row.third }}</span>
              <span v-if="row.third == ''">{{ row.third }}</span>
            </template>

            <span v-if="row.first != ''"> {{ row.first }}></span>
            <span v-if="row.first == ''"> {{ row.first }}</span>
            <span v-if="row.second != ''">{{ row.second }}></span>
            <span v-if="row.second == ''">{{ row.second }}</span>
            <span v-if="row.third != ''">{{ row.third }}</span>
            <span v-if="row.third == ''">{{ row.third }}</span>
          </Tooltip>
        </template>
        <template v-if="row.level == '一般数据'">
          <Tooltip placement="top" max-width="300" theme="light">
            <template #content>
              <span v-for="(each, n) in row.dataType" :key="n">
                <template v-if="n != row.dataType.length - 1">
                  <span> {{ row.first }}></span>
                  <span v-for="(m, t) in each" :key="t">
                    <span v-if="t != each.length - 1">{{ m }}></span>
                    <span v-else>{{ m }}</span> </span>；
                </template>
                <template v-else>
                  <span> {{ row.first }}></span>
                  <span v-for="(m, t) in each" :key="t">
                    <span v-if="t != each.length - 1">{{ m }}></span>
                    <span v-else>{{ m }}</span>
                  </span>
                </template>
              </span>
            </template>
            <span v-for="(each, n) in row.dataType" :key="n">
              <template v-if="n != row.dataType.length - 1">
                <span> {{ row.first }}></span>
                <span v-for="(m, t) in each" :key="t">
                  <span v-if="t != each.length - 1">{{ m }}></span>
                  <span v-else>{{ m }}</span> </span>；
              </template>
              <template v-else>
                <span> {{ row.first }}></span>
                <span v-for="(m, t) in each" :key="t">
                  <span v-if="t != each.length - 1">{{ m }}></span>
                  <span v-else>{{ m }}</span>
                </span>
              </template>
            </span>
          </Tooltip>
        </template>
      </template>
      <template #action="{ row }">
        <Select v-model="row.actionType" @on-change="handleAction(row)" :transfer="true" :disabled="row.state == 1">
          <!-- <Option
            v-for="item in actionList"
            :value="item.value"
            :key="item.value"
            >{{ item.label }}</Option
          > -->
          <Option value="编辑" v-if="row.state == 0 || row.state == 2">编辑</Option>
          <Option value="删除">删除</Option>
          <Option value="复制" v-if="!isDisabled">复制</Option>
          <Option value="复核记录" v-if="row.state == 2">复核记录</Option>
        </Select>
      </template>
    </Table>

    <div class="page" v-if="total > 50">
      <Page :total="total" show-elevator show-total @on-change="changePage" :page-size="pageSize" />
    </div>
  </div>
  <noData v-if="total == 0" />
  <Record />
  <Modal v-model="modal1" sticky scrollable :mask-closable="true" class="custom-modal-footer-block"
    @on-cancel="modal1 = false">
    <template #header>
      <p class="title">提醒</p>
      <div class="modal-close" @click="modal1 = false">
        关闭 <span>ESC</span>
      </div>
    </template>
    <div class="alarm-info">你确定要删除吗？</div>
    <template #footer>
      <div class="btn btn-default" @click="confirmAction">确认</div>
    </template>
  </Modal>

  <errorModal ref="errorRef" />
  <submitModal ref="submitModalRef" />
</template>

<script setup>
import { ref, watch, inject, onMounted, onBeforeUnmount } from "vue";
import { Message } from "view-ui-plus";
import { useRouter } from "vue-router";
import bus from "@/utils/bus.js";
import { Modal } from "view-ui-plus";
import noData from "@/components/noData.vue";
import Record from "../examineList/components/record.vue";
import DownFiles from "@/utils/dowFile";
import submitModal from "./components/submitModal.vue";
import errorModal from "@/components/errorModal.vue";
import qs from "qs";
let name = ref("");
let dataType = ref([]);
let level = ref("");
let dataTypeList = ref([]);
let columns = [
  {
    type: "selection",
    width: 50,
    align: "center",
    // fixed: "left",
  },
  {
    title: "数据名称",
    slot: "dataName",
    resizable: true,
  },
  {
    title: "状态",
    slot: "status",
    align: "center",
    resizable: true,
  },
  {
    title: "数据分类",
    slot: "type",
    resizable: true,
  },
  {
    title: "数据级别",
    slot: "level",
    align: "left",
    resizable: true,
  },
  {
    title: "大小",
    slot: "dataSize",
    align: "center",
    resizable: true,
  },
  // {
  //   title: "安全负责人姓名",
  //   key: "chargeName",
  //   width: 150,
  //   resizable: true,
  // },
  {
    title: "填报人",
    key: "submitter",
    resizable: true,
  },
  {
    title: "更新日期",
    key: "updateDataTime",
    // align: "center",
    resizable: true,
  },
  {
    title: "操作",
    slot: "action",
    align: "center",
    width: 150,
    // fixed: "right",
    // resizable: true,
  },
];
let tableData = ref([]);
let page = ref(1);
let pageSize = ref(50);
let total = ref(0);
let selection = ref([]);
let selectionArr = ref([]);
let router = useRouter();

let actionList = [
  {
    label: "编辑",
    value: "编辑",
  },
  // {
  //   label: "提交",
  //   value: "提交",
  // },
  {
    label: "删除",
    value: "删除",
  },
  {
    label: "复制",
    value: "复制",
  },
  // {
  //   label: "复核记录",
  //   value: "复核记录",
  // },
];

const props = {
  multiple: false,
  checkStrictly: true,
};
//是否禁用某个按钮
let isDisabled = ref(false);
const prop = defineProps({
  disabled: Boolean,
});
watch(
  () => prop.disabled,
  (val) => {
    isDisabled.value = val;
  },
  { deep: true, immediate: true }
);

function changePage(val) {
  page.value = val;
  getTableData();
}
let axios = inject("axios");
const getTableData = () => {
  let url = `/content/unfinished`;
  let params = {
    pageOffset: page.value,
    pageSize: pageSize.value,
    dataName: name.value.replace(/%/g,'%25'),
    level: level.value,
  };
  if (dataType.value.length > 0) {
    params.first = dataType.value[0];
    params.second = dataType.value[1];
    params.third = dataType.value[2];
  }
  axios.get(url, { params }).then((res) => {
    if (res.data.code == 200) {
      let data = res.data.data;
      tableData.value = data.content.map((item) => {
        item.updateDataTime =
          item.updateDataTime != null
            ? item.updateDataTime.substring(0, 10)
            : "";
        item.second = item.second != null ? item.second.join(",") : "";
        item.third = item.third != null ? item.third.join(",") : "";
        return item;
      });
      tableData.value.forEach((item) => {
        if (item.state == 1) {
          item._disabled = true;
        }
      });
      total.value = data.count;
      console.log(total.value)
      //通知父组件总个数
      bus.emit("unCount", total.value);
    }
  });
};
function changeAll(arr) {
  console.log(arr)
  selectionArr.value = arr;
  selection.value = arr.map((item) => {
    return item.id;
  });
}
function handleSearch() {
  page.value = 1;
  selection.value = []
  getTableData();
}

const getLevelData = () => {
  let url = `/content/index`;
  axios.get(url).then((res) => {
    if (res.data.code == 200) {
      dataTypeList.value = res.data.data;
    }
  });
};

let currentOperData = ref({});
let modal1 = ref(false);
let isAllDel = ref(false); //false代表单个删除，true代表批量删除
//批量删除
const DelData = () => {
  if (selection.value.length == 0) {
    return Message.error({
      background: true,
      content: "请选择数据后再进行操作",
      duration: 3,
    });
  }
  let url = `/content/del`;
  let params = {
    dataIds: selection.value.join(","),
  };
  axios.post(url, qs.stringify(params)).then((res) => {
    if (res.data.code == 200) {
      Message.success("删除成功");
      handleSearch();
      bus.emit("getCount");
    } else {
      Message.error(res.data.message);
    }
  });
};
//区分是点击单个的删除还是批量删除
const handleSingle = (val) => {
  if (val && selection.value.length == 0) {
    return Message.error({
      background: true,
      content: "请选择数据后再进行操作",
      duration: 3,
    });
  }
  modal1.value = true;
  isAllDel.value = val;
};
//确认删除
const confirmAction = () => {
  modal1.value = false;
  if (isAllDel.value) {
    DelData();
  } else {
    singleDelData(currentOperData.value);
  }
};

//单个删除
const singleDelData = (row) => {
  let url = `/content/del`;
  let params = {
    dataIds: row.id,
  };
  axios.post(url, qs.stringify(params)).then((res) => {
    if (res.data.code == 200) {
      Message.success("删除成功");
      handleSearch();
      bus.emit("getCount");
    } else {
      Message.error(res.data.message);
    }
  });
};

//提交弹框提醒
let modal2 = ref(false);
let isAlert = ref(false);
const handleConfirmAlert = (val) => {
  modal2.value = true;
  isAlert.value = val;
};

//提交确认
const confirmSubmit = () => {
  if (selection.value.length == 0) {
    return Message.error({
      background: true,
      content: "请选择数据后再进行操作",
      duration: 3,
    });
  }
  modal2.value = false;
  submitModalRef.value.modify();
  //告诉弹框选中了哪些数据
  bus.emit("selectArr", selectionArr.value);
};

let submitModalRef = ref(null);

//批量提交
const handleSubmit = () => {
  if (selection.value.length == 0) {
    return Message.error({
      background: true,
      content: "请选择数据后再进行操作",
      duration: 3,
    });
  }
  let url = `/content/subid`;
  let params = {
    dataIds: selection.value.join(","),
  };
  axios.post(url, qs.stringify(params)).then((res) => {
    if (res.data.code == 200) {
      Message.success("提交成功");
      handleSearch();
      bus.emit("getCount");
    } else {
      Message.error(res.data.message);
    }
  });
};
//单个提交
const singleSubmit = (row) => {
  let url = `/content/subid`;
  let params = {
    dataIds: row.id,
  };
  axios.post(url, qs.stringify(params)).then((res) => {
    if (res.data.code == 200) {
      Message.success("提交成功");
      handleSearch();
      bus.emit("getCount");
    } else {
      Message.error(res.data.message);
    }
  });
};
//复制
const handleCopy = (row) => {
  let url = `/content/copy`;
  let params = {
    dataId: row.id,
  };
  let user = JSON.parse(localStorage.getItem('User'));
  let Authorization = ''
  if (user && user != null && user != undefined) {
    Authorization = 'Bearer ' + user.auth_token;
  }
  axios.post(url, qs.stringify(params), {
    headers: {
      "Authorization": Authorization
    },
  }).then((res) => {
    if (res.data.code == 200) {
      Message.success("复制成功");
      handleSearch();
      bus.emit("getCount");
    } else {
      Message.error(res.data.message);
    }
  });
};
//操作
let actionType = ref("");
const handleAction = (row) => {
  currentOperData.value = null;
  switch (row.actionType) {
    case "编辑":
      handleEdit(row);
      break;
    case "提交":
      currentOperData.value = row;
      handleConfirmAlert(false);
      break;
    case "删除":
      currentOperData.value = row;
      handleSingle(false);
      break;
    case "复制":
      handleCopy(row);
      break;
    case "复核记录":
      history(row);
      break;
  }
};
//继续编辑
const handleEdit = (row) => {
  let level = row.level;
  if (level == "重要数据") {
    router.push({
      name: "importantData",
      query: {
        id: row.id,
        // isEdit: true,
      },
    });
  }
  if (level == "核心数据") {
    router.push({
      name: "coreData",
      query: {
        id: row.id,
        // isEdit: true,
      },
    });
  }
};

// 复核记录
const history = (row) => {
  bus.emit("openHistory", row.id);
};
const handleReset = () => {
  name.value = "";
  dataType.value = [];
  level.value = "";
  handleSearch();
};

//导出清单
const exportList = () => {
  if (total.value == 0) {
    return Message.error({
      background: true,
      content: "暂无可导出的数据",
      duration: 3,
    });
  }
  // let url = `/content/export`;
  // let params = {
  //   dataIds: "",
  // };
  if (selection.value.length == 0) {
    return Message.error({
      background: true,
      content: "请选择数据后再进行导出",
      duration: 3,
    });
  }
  let url = `/file/generateDcList`;
  let params = {
    ids: "",
  };
  if (selection.value.length > 0) {
    params.ids = selection.value.join(",");
  }
  // params = {
  //   ...params,
  //   dataName: name.value,
  //   level: level.value,
  // };
  // if (dataType.value.length > 0) {
  //   params.first = dataType.value[0];
  //   params.second = dataType.value[1];
  //   params.third = dataType.value[2];
  // }

  // axios
  //   .post(url, qs.stringify(params), { responseType: "blob" })
  //   .then((res) => {
  //     if (res.status == 200) {
  //       const blob = res.data;
  //       // 获取文件名
  //       const content = res.headers["content-disposition"];
  //       const fileName = content && content.split(";")[1].split("filename=")[1];
  //       DownFiles(blob, fileName);
  //     } else {
  //       Message.error(res.data.message);
  //     }
  //   });
  axios.get(url, { params }).then((res) => {
    if (res.data.code == 200) {
      let url = res.data.data.fileDownUrl;
      window.open(url);
    }else{
      Message.error(res.data.message);
    }
  });
};

onMounted(() => {
  getLevelData();
  handleSearch();

  bus.on("searchData", () => {
    handleSearch();
  });
});
onBeforeUnmount(() => {
  bus.all.delete("searchData");
});
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
