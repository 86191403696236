<template>
  <div class="search">
    <ul>
      <li>
        <Input placeholder="输入名称" v-model="name" class="width-400" @on-enter="handleSearch" />
      </li>
      <li>
        <el-cascader :props="props" :options="dataTypeList" v-model="dataType" filterable class="width-400"
          placeholder="选择数据分类" />
      </li>
      <li>
        <!-- <span>数据级别</span> -->
        <Select placeholder="选择数据级别" class="width-400" v-model="level">
          <Option label="重要数据" value="重要数据"></Option>
          <Option label="核心数据" value="核心数据"></Option>
          <!-- <Option label="一般" value="一般"></Option> -->
        </Select>
      </li>
      <li>
        <div class="btn search-btn flex-center-center" @click="handleSearch">
          <div class="img"></div>
        </div>
        <div class="btn reset-btn flex-center-center" @click="handleReset"
          v-if="name != '' || dataType.length > 0 || level != ''">
          <div class="img"></div>
        </div>
      </li>
    </ul>
    <li class="static">
      <span>共 <span class="bold">{{ total }}</span>项</span>
      <span v-if="selection.length > 0">已选 <span class="bold">{{ selection.length }}</span>项</span>
    </li>
  </div>
  <div class="table" v-if="total > 0">
    <Table :columns="columns" :data="tableData" border>
      <template #dataName="{ row }">
        <!-- <span @click="handleRouter(row)" class="name">{{ row.dataName }}</span> -->
        <Tooltip :content="row.dataName" placement="top" max-width="300" theme="light" :transfer="true">
          <span @click="handleRouter(row)" class="name">{{
            row.dataName
          }}</span>
        </Tooltip>
      </template>
      <template #status="{ row }">
        <span v-if="row.state == 0" class="draft">草稿</span>
        <span v-if="row.state == 1" class="examine">复核中</span>
        <span v-if="row.state == 2" class="nothrough">未通过</span>
        <span class="through" v-if="row.state == 3">通过</span>
      </template>
      <template #dataSize="{ row }">
        <span>{{ row.dataSize }}GB</span>
      </template>
      <template #level="{ row }">
        <span>{{ row.level }}</span>
        <div class="rate" v-if="row.level == '核心数据'">
          <img src="../../assets/img/级别.png" alt="" />
          <img src="../../assets/img/级别.png" alt="" />
          <img src="../../assets/img/级别.png" alt="" />
        </div>
        <div class="rate" v-if="row.level == '重要数据'">
          <img src="../../assets/img/级别.png" alt="" />
          <img src="../../assets/img/级别.png" alt="" />
        </div>
        <div class="rate" v-if="row.level == '一般数据'">
          <img src="../../assets/img/级别.png" alt="" />
        </div>
      </template>
      <template #reviewer="{ row }">
        <span v-if="row.reviewer">{{ row.reviewer }}</span>
      </template>
      <template #type="{ row }">
        <template v-if="row.level == '重要数据' || row.level == '核心数据'">
          <Tooltip placement="top" max-width="300" theme="light">
            <template #content>
              <span> {{ row.first }}></span>
              <span>{{ row.second }}></span>
              <span>{{ row.third }}</span>
            </template>
            <span> {{ row.first }}></span>
            <span>{{ row.second }}></span>
            <span>{{ row.third }}</span>
          </Tooltip>
        </template>
        <template v-if="row.level == '一般数据'">
          <Tooltip placement="top" max-width="300" theme="light">
            <template #content>
              <span v-for="(each, n) in row.dataType" :key="n">
                <template v-if="n != row.dataType.length - 1">
                  <span> {{ row.first }}></span>
                  <span v-for="(m, t) in each" :key="t">
                    <span v-if="t != each.length - 1">{{ m }}></span>
                    <span v-else>{{ m }}</span> </span>；
                </template>
                <template v-else>
                  <span> {{ row.first }}></span>
                  <span v-for="(m, t) in each" :key="t">
                    <span v-if="t != each.length - 1">{{ m }}></span>
                    <span v-else>{{ m }}</span>
                  </span>
                </template>
              </span>
            </template>
            <span v-for="(each, n) in row.dataType" :key="n">
              <template v-if="n != row.dataType.length - 1">
                <span> {{ row.first }}></span>
                <span v-for="(m, t) in each" :key="t">
                  <span v-if="t != each.length - 1">{{ m }}></span>
                  <span v-else>{{ m }}</span> </span>；
              </template>
              <template v-else>
                <span> {{ row.first }}></span>
                <span v-for="(m, t) in each" :key="t">
                  <span v-if="t != each.length - 1">{{ m }}></span>
                  <span v-else>{{ m }}</span>
                </span>
              </template>
            </span>
          </Tooltip>
        </template>
      </template>
      <template #action="{ row }">
        <Select v-model="row.actionType" @on-change="handleAction(row)" :transfer="true" placeholder="选择">
          <!-- <Option
            v-for="item in actionList"
            :value="item.value"
            :key="item.value"
            >{{ item.label }}</Option
          > -->
          <!-- <Option
            v-for="item in actionList"
            :value="item.value"
            :key="item.value"
            >{{ item.label }}</Option
          > -->
          <Option value="详情">详情</Option>
          <!-- <Option value="变更">变更</Option> -->
          <Option value="复制" v-if="!isDisabled">复制</Option>
        </Select>
      </template>
    </Table>

    <div class="page" v-if="total > 10">
      <Page :total="total" show-elevator show-total @on-change="changePage" :page-size="pageSize" />
    </div>
  </div>
  <noData v-if="total == 0" />
</template>
  
  <script setup>
import { ref, inject, onMounted, onBeforeUnmount, watch } from "vue";
import { Message } from "view-ui-plus";
import bus from "@/utils/bus.js";
import router from "@/router";
import qs from "qs";
import noData from "@/components/noData.vue";
//是否禁用某个按钮
let isDisabled = ref(false);
const prop = defineProps({
  disabled: Boolean
})
watch(() => prop.disabled, (val) => {
  isDisabled.value = val;
}, { deep: true, immediate: true })



let name = ref("");
let dataType = ref([]);
let level = ref("");
let dataTypeList = ref([]);
let columns = [
  {
    title: "数据名称",
    slot: "dataName",
    minWidth: 24,
    resizable: true,
  },
  {
    title: "状态",
    slot: "status",
    // align: "center",
    minWidth: 16,
    resizable: true,
  },
  {
    title: "数据分类",
    slot: "type",
    minWidth: 24,
    resizable: true,
  },
  {
    title: "数据级别",
    slot: "level",
    align: "left",
    width: 140,
    minWidth: 24,
    resizable: true,
  },
  {
    title: "数据大小",
    slot: "dataSize",
    align: "center",
    minWidth: 12,
    resizable: true,
  },
  {
    title: "填报人",
    key: "submitter",
    minWidth: 12,
    resizable: true,
  },
  // {
  //   title: "复核机构",
  //   key: "evaluationOrg",
  //   // align: "center",
  //   width: 140,
  //   resizable: true,
  // },

  // {
  //   title: "复核人",
  //   slot: "reviewer",
  //   // align: "center",
  //   width: 120,
  //   resizable: true,
  // },

  {
    title: "提交日期",
    key: "dataTime",
    // align: "center",
    minWidth: 14,
    resizable: true,
  },
  // {
  //   title: "复核日期",
  //   key: "auditPassData",
  //   // align: "center",
  //   width: 140,
  //   resizable: true,
  // },
  {
    title: "操作",
    slot: "action",
    // align: "center",
    width: 150,
    resizable: true,
    // fixed: "right",
  },
];
let tableData = ref([]);
let page = ref(1);
let pageSize = ref(10);
let total = ref(0);
let selection = ref([]);

let actionList = [
  {
    label: "详情",
    value: "详情",
  },
  {
    label: "变更",
    value: "变更",
  },
  {
    label: "复制",
    value: "复制",
  },
];

const props = {
  multiple: false,
  checkStrictly: true,
};
function changeAll(arr) {
  selection.value = arr.map((item) => {
    return item.id;
  });
}
function changePage(val) {
  page.value = val;
  getTableData();
}
let axios = inject("axios");
const getTableData = () => {
  let url = `/content/completed`;
  let params = {
    pageOffset: page.value,
    pageSize: pageSize.value,
    dataName: name.value.replace(/%/g,'%25'),
    level: level.value,
  };
  if (dataType.value.length > 0) {
    params.first = dataType.value[0];
    params.second = dataType.value[1];
    params.third = dataType.value[2];
  }
  axios.get(url, { params }).then((res) => {
    if (res.data.code == 200) {
      let data = res.data.data;
      tableData.value = data.content.map((item) => {
        item.dataTime =
          item.dataTime != null ? item.dataTime.substring(0, 10) : "";
        item.auditDataTime =
          item.auditDataTime != null ? item.auditDataTime.substring(0, 10) : "";
        item.second = Array.isArray(item.second)
          ? item.second.join(";")
          : item.second;
        item.third = Array.isArray(item.third)
          ? item.third.join(";")
          : item.third;
        item.reviewer = Array.isArray(item.reviewer)
          ? item.reviewer.join(";")
          : item.reviewer;
        return item;
      });
      total.value = data.count;
      //通知父组件总个数
      bus.emit("count", total.value);
    }
  });
};
function handleSearch() {
  selection.value = []

  page.value = 1;
  getTableData();
}
//获取层级数据
const getLevelData = () => {
  let url = `/content/index`;
  axios.get(url).then((res) => {
    if (res.data.code == 200) {
      dataTypeList.value = res.data.data;
    }
  });
};
//操作
let actionType = ref("");
const handleAction = (row) => {
  switch (row.actionType) {
    case "详情":
      handleRouter(row);
      break;
    case "变更":
      handleEdit(row);
      break;
    case "删除":
      singleDelData(row);
      break;
    case "复制":
      handleCopy(row);
      break;
  }
};
//复制
const handleCopy = (row) => {
  let url = `/content/copy`;
  let params = {
    dataId: row.id,
  };
  let user = JSON.parse(localStorage.getItem('User'));
  let Authorization = ''
  if (user && user != null && user != undefined) {
    Authorization = 'Bearer ' + user.auth_token;
  }
  axios.post(url, qs.stringify(params), {
    headers: {
      "Authorization": Authorization
    },
  }).then((res) => {
    if (res.data.code == 200) {
      Message.success("复制成功");
      handleSearch();
      bus.emit("getCount");
    } else {
      Message.error(res.data.message);
    }
  });
};
//单个删除
const singleDelData = (row) => {
  let url = `/content/del`;
  let params = {
    dataIds: row.id,
  };
  axios.post(url, qs.stringify(params)).then((res) => {
    if (res.data.code == 200) {
      Message.success("删除成功");
      handleSearch();
      bus.emit("getCount");
    } else {
      Message.error(res.data.message);
    }
  });
};
//变更详情
const handleEdit = (row) => {
  let level = row.level;
  let status = [3];
  if (level == "重要数据" || level == "核心数据") {
    if (status.includes(row.state)) {
      router.push({
        name: "importantData",
        query: {
          id: row.id,
          // isEdit: true,
        },
      });
    } else {
      router.push({
        name: "importantData",
        query: {
          id: row.id,
        },
      });
    }
  } else if (level == "一般数据") {
    if (status.includes(row.state)) {
      router.push({
        name: "commonData",
        query: {
          id: row.id,
          // isEdit: true,
        },
      });
    } else {
      router.push({
        name: "commonData",
        query: {
          id: row.id,
        },
      });
    }
  }
};
const handleReset = () => {
  name.value = "";
  dataType.value = [];
  level.value = "";
  handleSearch();
};

//详情
const handleRouter = (row) => {
  let level = row.level;
  if (level == "重要数据" || level == "核心数据") {
    router.push({
      name: "fillDetails",
      query: {
        id: row.id,
      },
    });
  } else if (level == "一般数据") {
    router.push({
      name: "fillComDetails",
      query: {
        id: row.id,
      },
    });
  }
};

onMounted(() => {

  getLevelData();
  handleSearch();

  bus.on("searchData", () => {
    handleSearch();
  });
});
onBeforeUnmount(() => {
  bus.all.delete("searchData");
});
</script>
  
  <style lang="scss" scoped>
@import "./index.scss";
</style>