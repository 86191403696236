<!-- 完成或未完成列表表头 -->
<template>
  <div class="header">
    <h2>数据目录填报</h2>
    <ul>
      <li :class="isShow == 0 ? 'active' : ''" @click="handleNav(0)">
        未完成({{ unCount }})
      </li>
      <li :class="isShow == 1 ? 'active' : ''" @click="handleNav(1)">
        已提交({{ count }})
      </li>
    </ul>
    <div class="button">
      <div class="btn btn-default" @click="modal2 = true" v-if="!isDisabled">
        完成年度填报
      </div>
      <div class="btn btn-default grey" v-if="isDisabled">已完成年度填报</div>
      <div class="btn-default btn-add" @click="handleAdd" v-if="!isDisabled">
        <Icon type="md-add" />新建
      </div>
      <CommonHeader />
    </div>
    <Modal
      v-model="modal2"
      sticky
      scrollable
      :mask-closable="true"
      class="custom-modal-footer-block"
      @on-cancel="modal2 = false"
    >
      <template #header>
        <p class="title">提醒</p>
        <div class="modal-close" @click="modal2 = false">
          关闭 <span>ESC</span>
        </div>
      </template>
      <div class="alarm-info">你确定要完成年度填报吗？</div>
      <template #footer>
        <div class="btn btn-default" @click="submitData">确认</div>
      </template>
    </Modal>
  </div>
</template>

<script setup>
import { ref, defineEmits, inject, onMounted, onBeforeUnmount } from "vue";
import { useRouter } from "vue-router";
import { Message } from "view-ui-plus";
import CommonHeader from "@/components/commonHeader.vue";
import bus from "@/utils/bus.js";

let isShow = ref(0); //0代表未完成，1代表已完成
let modal2 = ref(false);
let emit = defineEmits(["showNav"]);
function handleNav(val) {
  isShow.value = val;
  emit("showNav", isShow.value);
}

let router = useRouter();
const handleAdd = () => {
  router.push("selectLevel");
};
let unCount = ref(0);
let count = ref(0);

let axios = inject("axios");
const getCount = () => {
  let url = `/content/count`;
  axios.get(url).then((res) => {
    if (res.data.code == 200) {
      let data = res.data.data;
      count.value = data.complete;
      unCount.value = data.unfinished;
      bus.emit("noticeCount", { count: count.value, unCount: unCount.value });
    }
  });
};
//完成年度填报
const submitData = () => {
  let url = `/content/completeFiling`;
  axios.get(url).then((res) => {
    modal2.value = false;
    if (res.data.code == 200) {
      Message.success({
        background: true,
        content: "年度填报成功",
        duration: 3,
      });
      bus.emit("noticeValidate");
    } else {
      Message.error({
        background: true,
        content: res.data.message,
        duration: 3,
      });
    }
  });
};
//是否禁用某个按钮
let isDisabled = ref(false);

onMounted(() => {
  getCount();
  bus.on("getCount", () => {
    getCount();
  });
  bus.on("disabledData", () => {
    isDisabled.value = true;
  });
});
onBeforeUnmount(() => {
  bus.all.delete("getCount");
  bus.all.delete("disabledData");
});
</script>


<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
  > h2 {
    font-family: PingFangSC-Medium;
    font-size: 26px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 33px;
    letter-spacing: 0px;
    color: #111111;
  }
  ul {
    display: flex;
    li {
      cursor: pointer;
      font-family: PingFangSC-Regular;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      color: #111111;
      line-height: 40px;
      height: 40px;
      width: 150px;
      letter-spacing: 0px;
      text-align: center;
    }
    li.active {
      background: #f6f6f6;
      border-radius: 10px;
      color: #111111;
      font-weight: bold;
    }
  }
}
.button {
  display: flex;
  align-items: center;
  > div {
    margin-left: 20px;
  }
  .btn-add {
    color: #fff;
    cursor: pointer;
    width: 115px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-radius: 20px;
    font-family: PingFangSC-Regular;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    i {
      padding-right: 5px;
    }
  }
  .icon {
    img {
      display: block;
      width: 20px;
      height: 18px;
    }
  }
}
.grey {
  background: #eeeeee;
  color: #555555;
}
</style>